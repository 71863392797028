import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Footer from 'ui-kit/footer';
import ScrollTop from 'ui-kit/scrolltop';
import GlobalStyles from 'ui-kit/global-styles';
import ThemeContainer from 'ui-kit/theme-container';

import { mq } from 'helpers/stylehelpers';
import Header from './header';
import logo from '../images/logo.png';

const Wrapper = styled.div`
    ${props =>
        props.displayInViewportHeight &&
        mq.medium`
            display: flex;
            flex-direction: column;
            height: 100vh;
        `};
`;

const Main = styled.main`
    ${props =>
        props.displayInViewportHeight &&
        mq.medium`
            flex-grow: 1;
            flex-basis: 0;
        `};
`;

/**
 * Rendert ein Standard-Layout
 * @param {Node} props.children Der Inhalt
 * @param {string} props.title Der Seitentitel
 * @param {string} props.description Die Meta-Description
 */
const Layout = ({ children, title, description, displayInViewportHeight }) => (
    <StaticQuery
        query={graphql`
            {
                allPagestructureJson {
                    edges {
                        node {
                            url
                            title
                        }
                    }
                }
            }
        `}
        render={data => {
            const pageStructure = data.allPagestructureJson.edges.map(({ node }) => node);

            return (
                <>
                    <Helmet
                        title={title}
                        meta={[{ name: 'description', content: description }]}
                        htmlAttributes={{
                            lang: 'de',
                        }}
                        link={[{ rel: 'preconnect', href: 'https://fast.fonts.net' }]}
                        script={[
                            {
                                type: 'application/javascript',
                                src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
                                'data-settings-id': '1D3AZy74k',
                                id: 'usercentrics-cmp',
                            },
                        ]}
                    />
                    <GlobalStyles />
                    <ThemeContainer theme={{ main: 'sbf' }}>
                        <Wrapper displayInViewportHeight={displayInViewportHeight}>
                            <Header
                                pageStructure={pageStructure}
                                logo={{
                                    image: logo,
                                    alt: 'Logo der Südbayerische Fleischwaren',
                                    title: 'Südbayerische Fleischwaren',
                                }}
                            />
                            <Main displayInViewportHeight={displayInViewportHeight}>
                                {children}
                            </Main>
                            <Footer marginToContent={false} />
                            <ScrollTop />
                        </Wrapper>
                    </ThemeContainer>
                    {/* eslint-disable react/no-danger */}
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: `
                            {
                                "@context": "http://schema.org",
                                "@type": "Organization",
                                "name": "Südbayerische Fleischwaren GmbH",
                                "description": "Die Südbayerische Fleischwaren GmbH hat ihren Hauptsitz in Ingolstadt und betreibt drei modernste Fleischwerke in Traunstein, Obertraubling und Ingolstadt.",
                                "logo": "https://www.suedbayerische-fleischwaren.de/logo.png",
                                "url": "https://www.suedbayerische-fleischwaren.de",
                                "telephone": "+49 (0) 841 / 96 634 - 0",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Robert-Bosch-Straße 13",
                                    "addressLocality": "Ingolstadt",
                                    "postalCode": "85053",
                                    "addressCountry": "Germany"
                                }
                            }
                        `,
                        }}
                    />
                    {/* eslint-enable react/no-danger */}
                </>
            );
        }}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    title: PropTypes.string,
    displayInViewportHeight: PropTypes.bool,
};

Layout.defaultProps = {
    description: '',
    title: 'Südbayerische Fleischwaren',
    displayInViewportHeight: false,
};

export default Layout;
